define("experience-studio/components/flight-sidebar", ["exports", "experience-studio/mixins/access-level", "experience-studio/config/environment", "ember-keyboard-shortcuts", "jquery"], function (_exports, _accessLevel, _environment, _emberKeyboardShortcuts, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = Ember.Component.extend(_accessLevel.default, {
    classNames: ['main-sidebar'],
    classNameBindings: ['isMainSidebarMinimized:minimized-main-sidebar'],
    isMainSidebarMinimized: false,
    session: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    av5TourGuide: Ember.inject.service(),
    storage: Ember.inject.service(),
    isExperiencesListView: null,
    isContentHubListView: null,
    isContextHubListView: null,
    reactHost: _environment.default.APP.reactES,
    newSSOHost: _environment.default.APP.newSSOHost,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.storage.getBoolean('isExperiencesListView')) {
        this.set('isExperiencesListView', true);
      } else {
        this.set('isExperiencesListView', false);
      }
      if (this.storage.getBoolean('isContentHubListView')) {
        this.set('isContentHubListView', true);
      } else {
        this.set('isContentHubListView', false);
      }
      if (this.storage.getBoolean('isContextHubListView')) {
        this.set('isContextHubListView', true);
      } else {
        this.set('isContextHubListView', false);
      }
    },
    // Note: these keybinds trigger anywhere in ES. This can be problematic in some cases,
    // and we may need to find a way to disable these binds later, but for the time being
    // if you are composing anything, there is a modal that will popup for unsaved work
    // so this should be fine. Developers should take into consideration when designing
    // pages in the future.
    keyboardShortcuts: {
      'command+m': {
        action: 'toggleMinimize',
        // action to trigger
        global: true,
        // whether to trigger inside input (default: true)
        preventDefault: true // (default: true)
      },

      '[': {
        action: 'toggleMinimize',
        global: false,
        preventDefault: true
      },
      'g e': {
        action: 'goToExperiences',
        global: false,
        preventDefault: true
      },
      'g c': {
        action: 'goToContentHub',
        global: false,
        preventDefault: true
      },
      'g x': {
        action: 'goToContextHub',
        global: false,
        preventDefault: true
      },
      'g p': {
        action: 'goToPushNotifications',
        global: false,
        preventDefault: true
      },
      'g t': {
        action: 'goToManageTeams',
        global: false,
        preventDefault: true
      },
      'g a': {
        action: 'goToAnalytics',
        global: false,
        preventDefault: true
      },
      'g n': {
        action: 'goToReactApp',
        global: false,
        preventDefault: true
      },
      'g s': {
        action: 'gotToNewSSOUI',
        global: false,
        preventDefault: true
      },
      'c e': {
        action: 'composeExperience',
        global: false,
        preventDefault: true
      },
      'c c': {
        action: 'composeContent',
        global: false,
        preventDefault: true
      },
      'c x': {
        action: 'composeContextRule',
        global: false,
        preventDefault: true
      },
      'c p': {
        action: 'composePushNotification',
        global: false,
        preventDefault: true
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    isTemplatedExperiencesEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      if (window.location.host === 'members1st-dev.us.flybits.com' || window.location.host === 'members1st-staging.us.flybits.com' || window.location.host === 'members1st.us.flybits.com') {
        return true;
      }
      switch (_environment.default.environment) {
        case 'x-development':
        case 'x-staging':
        case 'x-production-demo':
          return true;
        default:
        // continue rest of logic
      }

      if (this.userPreferences.getPreference('templatedExperiences')) {
        return this.userPreferences.getPreference('templatedExperiences').value;
      } else {
        return false;
      }
    }),
    isNewSSOuiEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      if (!_environment.default.environment.includes('production') && !_environment.default.environment.includes('staging') && this.userPreferences.getPreference('newSSOHost')) {
        return this.userPreferences.getPreference('newSSOHost').value;
      } else {
        return false;
      }
    }),
    analyticsPageV5Preference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('analyticsPageV5');
    }),
    canAccessPermissionsHub: Ember.computed('session.user.accessLevel.name', function () {
      return this.canAccessRoute('permissions-hub', this.get('session.user.accessLevel.value'));
    }),
    esExpressLinkPreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('experienceStudioExpressLink');
    }),
    userPreferencePagePreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('userPreferencePage');
    }),
    isAnalyticsPageVisible: Ember.computed(function () {
      var isAnalyticsPageVisible = true;
      var domain = window.location.origin;
      var blacklist = ['https://tdint3.flybits.com', 'https://tid-pat.flybits.com', 'https://tid-prod.flybits.com'];
      if (blacklist.includes(domain)) {
        isAnalyticsPageVisible = false;
      }
      return isAnalyticsPageVisible;
    }),
    isEsExpressDropdownItemVisible: Ember.computed('esExpressLinkPreference.value', function () {
      var value = false;
      if (!_environment.default.environment.includes('production')) {
        value = true;
      } else if (this.esExpressLinkPreference) {
        value = this.esExpressLinkPreference.get('value');
      }
      return value;
    }),
    isSettingsDropdownItemVisible: Ember.computed('userPreferencePagePreference.value', function () {
      var value = false;
      if (this.userPreferencePagePreference) {
        value = this.userPreferencePagePreference.get('value');
      }
      return value;
    }),
    routeListViewPreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('routeListView');
    }),
    nestorLinkPreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('nestorLink');
    }),
    isNestorVisible: Ember.computed('nestorLinkPreference.value', function () {
      var value = false;
      var domain = window.location.origin;
      var whitelist = _environment.default.APP.nestorWhiteList || [];
      if (!_environment.default.environment.includes('production')) {
        value = true;
      } else if (whitelist.includes(domain)) {
        value = true;
      } else if (this.nestorLinkPreference) {
        value = this.nestorLinkPreference.get('value');
      }
      return value;
    }),
    nestorUrl: Ember.computed('config.APP.nestorUrl', function () {
      var token = this.session.token;
      var env = _environment.default.environment;
      var url = "".concat(_environment.default.APP.nestorUrl, "/authorize/?environment=").concat(env, "&token=").concat(token);
      return url;
    }),
    developerPortalUrl: Ember.computed('session.user.accessLevel.value', function () {
      var accessLevel = this.get('session.user.accessLevel.value') || 0;
      var token = this.session.token;
      var tenantId = '';
      var url = _environment.default.APP.developerPortalUrl;
      try {
        tenantId = this.session.decodeToken(token).tenantID;
      } catch (exception) {} // eslint-disable-line no-empty

      var host = window.location.host;
      var projects = _environment.default.APP.thirdPartySignInProjects;
      var authUrl = '';
      if (accessLevel >= 20) {
        if (Array.isArray(projects) && projects.includes(host)) {
          var relayState = _environment.default.APP.thirdPartySignInES2;
          if (relayState && Array.isArray(relayState) && relayState.includes(host)) {
            //redirect back to ES2.0 after login
            authUrl = "".concat(_environment.default.APP.hostUrl, "/sso/auth/saml/login?subdomain=").concat(host, "&relayState=es2");
          } else {
            authUrl = "".concat(_environment.default.APP.hostUrl, "/sso/auth/saml/login?subdomain=").concat(host);
          }
        } else {
          authUrl = _environment.default.APP.authenticationUrl;
        }
      } else {
        authUrl = _environment.default.APP.authenticationUrl;
      }
      return "".concat(url, "/external-auth-redirect/").concat(tenantId, "?token=").concat(token, "&authUrl=").concat(authUrl);
    }),
    esExpressUrl: Ember.computed('session.user.accessLevel.value', function () {
      var accessLevel = this.get('session.user.accessLevel.value') || 0;
      var tenantId = '';
      var url = _environment.default.APP.esExpressUrl;
      var token = this.session.token;
      try {
        tenantId = this.session.decodeToken(token).tenantID;
      } catch (exception) {} // eslint-disable-line no-empty

      if (token && tenantId && accessLevel >= 20) {
        url = "".concat(url, "/#/authorize/?environment=").concat(_environment.default.environment, "&token=").concat(token);
      } else {
        url = "".concat(url, "/#/access-denied");
      }
      return url;
    }),
    versionDropdownItemText: Ember.computed(function () {
      return "".concat(_environment.default.modulePrefix, " ").concat(_environment.default.APP.appVersion);
    }),
    actions: {
      goToReactApp: function goToReactApp() {
        var token = this.get('session.token');
        var origin = window.location.href;
        var tenantId = this.session.decodeToken(this.session.token).tenantID;
        var redirectUrl = "".concat(this.reactHost, "/redirect?path=project/").concat(tenantId, "&origin=").concat(encodeURIComponent(origin), "&token=").concat(token);
        location.replace(redirectUrl);
      },
      gotToNewSSOUI: function gotToNewSSOUI() {
        var redirectUrl = "".concat(this.newSSOHost);
        location.replace(redirectUrl);
      },
      goToExperiences: function goToExperiences() {
        (0, _jquery.default)(this.element).find('.nav').find('.nav-link').get(0).click();
      },
      goToContentHub: function goToContentHub() {
        (0, _jquery.default)(this.element).find('.nav').find('.nav-link').get(1).click();
      },
      goToContextHub: function goToContextHub() {
        (0, _jquery.default)(this.element).find('.nav').find('.nav-link').get(2).click();
      },
      goToPushNotifications: function goToPushNotifications() {
        (0, _jquery.default)(this.element).find('.nav').find('.nav-link').get(3).click();
      },
      goToManageTeams: function goToManageTeams() {
        (0, _jquery.default)(this.element).find('.nav').find('.nav-link').get(4).click();
      },
      goToAnalytics: function goToAnalytics() {
        (0, _jquery.default)(this.element).find('.nav').find('.nav-link').get(5).click();
      },
      composeExperience: function composeExperience() {
        (0, _jquery.default)(this.element).find('.new-button-container .dropdown .dropdown-menu a').get(0).click();
      },
      composeContent: function composeContent() {
        (0, _jquery.default)(this.element).find('.new-button-container .dropdown .dropdown-menu a').get(1).click();
      },
      composeContextRule: function composeContextRule() {
        (0, _jquery.default)(this.element).find('.new-button-container .dropdown .dropdown-menu a').get(2).click();
      },
      composePushNotification: function composePushNotification() {
        (0, _jquery.default)(this.element).find('.new-button-container .dropdown .dropdown-menu a').get(3).click();
      },
      toggleMinimize: function toggleMinimize() {
        this.toggleProperty('isMainSidebarMinimized');
      },
      toggleMinimizeKeyPress: function toggleMinimizeKeyPress() {
        if (event.key === 'Enter') {
          this.toggleProperty('isMainSidebarMinimized');
        }
      },
      didLogoLoad: function didLogoLoad() {
        this.av5TourGuide.set('isMenuIconLoaded', true);
      },
      showNotification: function showNotification(options) {
        this.showNotification(options);
      }
    }
  });
  _exports.default = _default;
});