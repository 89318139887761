define("experience-studio/app", ["exports", "ember-resolver", "ember-load-initializers", "experience-studio/config/environment"], function (_exports, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Disable Sentry for now (getting an error from sentry complaining about the size of the payload)
  // import { InitSentryForEmber } from "@sentry/ember";
  // if (window.location.host.indexOf('localhost') === -1) {
  // InitSentryForEmber();
  // }
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default
  });
  console.log("".concat(_environment.default.modulePrefix, " ").concat(_environment.default.APP.appVersion)); // eslint-disable-line

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});